import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { User } from '../models/auth.models';

const host = "https://node-saifmohsen.herokuapp.com"; //"http://localhost:3000"; 

@Injectable({ providedIn: 'root' })
export class MailService {
    constructor(private http: HttpClient) { }
    
    contactMe(data) {
        return this.http.post(host + "/sendmail", data);
    }
}
